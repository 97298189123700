<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">
          {{ customer.description }} - Custom Settings
        </v-card-title>
        <div class="pa-5">
          <v-form ref="form">
            <v-text-field
              prepend-icon="mdi-text"
              label="Sign Up Mail Subject"
              v-model="customer.sign_up_mail_subject"
              outlined
            ></v-text-field>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Sign Up Mail Body</div>
                <div class="mb-3 label text-small">
                  <span class="grey--text">Usable variables:</span> {name}
                  {username} {password}
                </div>
                <HtmlEditor
                  v-model="customer.sign_up_mail_body"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>
            <v-text-field
              prepend-icon="mdi-text"
              label="Forgot Mail Subject"
              v-model="customer.forgot_mail_subject"
              outlined
            ></v-text-field>
            <div class="d-flex w-full mb-8 pb-2 pr-1">
              <v-icon class="pr-2 html-icon">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Forgot Mail Body</div>
                <div class="mb-3 label text-small">
                  <span class="grey--text">Usable variables:</span> {url}
                </div>
                <HtmlEditor
                  v-model="customer.forgot_mail_body"
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>
            <v-row justify="end">
              <v-btn
                color="primary"
                @click="submit"
                :disabled="loading"
                :loading="loading"
              >
                Save Settings
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import HtmlEditor from "@/components/HtmlEditor";
import { getLanguages } from "@/services/languageService";
import { each, get, isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Customer Custom Settings",
  },

  components: { HtmlEditor },

  data() {
    return {
      id: get(this.$route, "params.id"),
      model: "customer",
      loading: false,
      customer: {
        description: null,
        username: null,
        password: null,
        sign_up_id: null,
        manager: 0,
        disabled: 0,
        language: "en",
        sign_up_mail_subject: null,
        sign_up_mail_body: null,
        forgot_mail_subject: null,
        forgot_mail_body: null,
      },
      languages: [],
    };
  },

  async mounted() {
    await this.setCustomer(this.id);
    this.customer = this.getCustomer;
    const { data } = await getLanguages();
    this.languages = data;
  },

  methods: {
    ...mapActions([
      "createCustomer",
      "editCustomer",
      "setCustomers",
      "showSnackbar",
      "setCustomer",
    ]),

    async submit() {
      if (!this.$refs.form.validate() || !isEmpty(this.usernameError)) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");

        return;
      }

      const payload = new FormData();
      each(this.customer, (value, key) => {
        payload.append(key, value === "<p></p>" ? null : value);
      });

      try {
        this.loading = true;
        await this.editCustomer({ id: this.id, payload });
        await this.showSnackbar("Customer successfully updated!");
        this.$router.push("/app/customer");
      } catch (error) {
        console.log(error);
        await this.showSnackbar("Something went wrong");
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["getCustomer"]),
  },
};
</script>
<style lang="scss" scoped></style>
